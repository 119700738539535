export enum DesignType {
  IMAGE = "image",
  VIDEO = "video",
}

export interface Creative {
  id: number;
  name: string;
  num_product_cells: number;
  num_products_required: number;
  thumbnail: string;
  ui_folder?: number | null;
}

export interface QuickCreativeInput {
  type: "image" | "video" | "gif";
  resolution: [number, number];
  creative: number;
  products: number[][];
}

export interface QuickCreativeResult {
  result?: string;
  download?: string;
  status: "started" | "done" | "failed";
  type: string;
  filetype?: string;
  name: string;
  data?: ArrayBuffer;
}

export interface CreativeLayerConfig {
  cols: number[];
  rows: number[];
  steps: number;
  grid_config: CreativeCell[];
}

export interface CreativeLayer {
  name: string;
  identifier: string;
  layers: CreativeLayer[];
  config?: CreativeLayerConfig;
  LAYER?: any;
  type: CreativeLayerType;
  // Identifier of parent
  parent?: string;
}

export enum CreativeLayerType {
  LAYER = "layer",
  GROUP = "group",
}

export interface CreativeCell {
  pos: [number, number];
  type: CreativeCellType;
  config: any;
  step_position: number;
}

export enum CreativeCellType {
  EMPTY = "",
  PRODUCT = "product",
  TEXT = "text",
  MEDIA = "media",
  SHAPE = "bg",
  PRODUCT_ASSET = "product_asset",
}

export interface DesignTemplate {
  id: number;
  thumbnail: string;
  spec: any;
  num_product_cells: number;
  num_products_required: number;
  guideline_name?: string;
}

export interface DesignTemplateGeneric {
  thumbnail: string;
  spec: any;
  num_product_cells: number;
  num_products_required: number;
}

export interface DesignTemplateGenericCategory {
  name: string;
  items: DesignTemplateGeneric[];
}

export interface DesignTemplateGenericSection {
  name: string;
  categories: DesignTemplateGenericCategory[];
}

export interface DesignTemplateSection {
  name: string;
  description: string;
  resolution: [number, number];
  items: DesignTemplate[];
}

export interface DesignTemplatesOutput {
  brand: DesignTemplateSection[];
  generic: DesignTemplateGenericSection[];
}
