import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import {
  DesignTemplateSection,
  DesignTemplatesOutput,
} from "@core/api-models/designs.models";
import { AdminService } from "@core/api/admin.service";
import { DesignsService } from "@core/api/designs.service";
import { UsersService } from "@core/api/users.service";
import { AccessService } from "@core/services/access.service";
import { AccountStateService } from "@core/services/account-state.service";

import { Observable, map } from "rxjs";

interface TemplateCategory {
  name: string;
  description?: string;
  items: any[];
}
interface TemplateSection {
  name: string;
  type: "brand" | "generic";
  categories: TemplateCategory[];
  special?: boolean;
  collapsed?: boolean;
}

@Component({
  selector: "ngx-creatives-templates",
  templateUrl: "./creatives-templates.component.html",
  styleUrls: ["./creatives-templates.component.scss"],
  standalone: false,
})
export class CreativesTemplatesComponent implements OnInit {
  private ds = inject(DesignsService);
  private router = inject(Router);
  private adminService = inject(AdminService);
  private route = inject(ActivatedRoute);
  private accessService = inject(AccessService);
  private usersService = inject(UsersService);
  private accountState = inject(AccountStateService);

  sections: TemplateSection[] = null;

  brandSection: TemplateSection;
  genericSections: TemplateSection[] = [];
  mySection: TemplateSection;

  isLoaded = false;

  _resolution?: [number, number] = null;
  @Input() set resolution(to: [number, number]) {
    this._resolution = to;
    this.filterSections();
  }

  @Input() openOnClick = true;

  // Can be used in demo mode where only templates are available, and lots of other stuff is hidden
  // @Input() demoMode = false;

  @Output() templateSelected = new EventEmitter<any>();

  @Input() admin = false;

  @Input() mode: "default" | "radio" = "default";

  @Input() showDesigns: boolean = true;

  @Input() hideStaticDesigns = false;

  currentGuide?: string = null;
  uniqueGuideNames: string[] = [];

  selection?: {
    section: number;
    category: number;
  } = null;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit(): void {
    if (this.admin) {
      const id = parseInt(this.route.snapshot.paramMap.get("id"));

      this.load(
        this.adminService.getAdminDesignTemplateDesigns(id).pipe(
          map((resp) => {
            return { brand: resp, generic: [] };
          }),
        ),
      );
    } else {
      const callback = () => {
        this.load(this.ds.getDesignTemplates());
      };
      if (!this.accessService.current.account.has_feed) {
        // Update access to make sure we know if they have a feed or not
        this.usersService.getUserAccess().subscribe({
          next: (access) => {
            this.accessService.updateAvailable(access, false);
            callback();
          },
          error: (_) => {
            callback();
          },
        });
      } else {
        callback();
      }
    }
  }

  select(sectionIndex: number, categoryIndex: number) {
    this.selection = {
      section: sectionIndex,
      category: categoryIndex,
    };
  }

  load(obs: Observable<DesignTemplatesOutput>) {
    obs.subscribe({
      next: (dt) => {
        if (dt.brand.length > 0) {
          const brandSection: TemplateSection = {
            name: "Branded Templates",
            type: "brand",
            categories: dt.brand,
          };

          // this.sections = dt.brand;
          // Figure out unique template names for filtering
          const gnSet = new Set<string>();
          brandSection.categories.forEach((s) => {
            s.items
              .filter((i) => i.guideline_name != null)
              .forEach((i) => gnSet.add(i.guideline_name));
          });

          this.uniqueGuideNames = Array.from(gnSet).sort((a, b) =>
            a.toLowerCase().localeCompare(b.toLowerCase()),
          );

          this.brandSection = brandSection;
        }

        // This should be backend logic, but we're still just testing
        this.genericSections = dt.generic
          .map((section) => {
            section.categories = section.categories
              .map((category) => {
                // Only items with no products if no feed
                if (!this.accessService.current.account.has_feed) {
                  category.items = category.items.filter(
                    (item) => item.num_product_cells === 0,
                  );
                }
                return category;
              })
              .filter((category) => category.items.length > 0)
              .filter((category) =>
                this.mode !== "radio"
                  ? category.name.toLowerCase() !== "onboarding"
                  : category.name.toLowerCase() === "onboarding",
              );

            return {
              name: section.name,
              categories: section.categories,
              type: "generic",
            } as TemplateSection;
          })
          .filter((section) => section.categories.length > 0);
      },
      complete: () => {
        this.isLoaded = true;
        this.filterSections();
        this.select(0, 0);
      },
    });
  }

  filterSections() {
    let brandSectionPart = [];

    if (this.brandSection) {
      const brandSection: TemplateSection = JSON.parse(
        JSON.stringify(this.brandSection),
      );

      brandSection.categories = brandSection.categories
        .filter((s: DesignTemplateSection) => {
          if (this._resolution) {
            return (
              s.resolution[0] === this._resolution[0] &&
              s.resolution[1] === this._resolution[1]
            );
          }
          return true;
        })
        .map((category) => {
          if (this.currentGuide) {
            category.items = category.items.filter(
              (i) =>
                i.guideline_name?.toLowerCase() ===
                this.currentGuide.toLowerCase(),
            );
          }
          return category;
        });

      brandSectionPart = [brandSection];
    }

    this.accountState.getData(true).subscribe({
      next: (state) => {
        this.mySection = {
          name: "My Content",
          type: "generic",
          special: true,
          categories: [{ name: "Start from existing design", items: [] }],
        };
        this.sections = [
          ...(!state.has_designs || !this.showDesigns ? [] : [this.mySection]),
          ...this.genericSections,
          ...brandSectionPart,
        ].filter((s) =>
          this.hideStaticDesigns
            ? s.name.toLowerCase() !== "static content"
            : true,
        );
      },
    });
  }

  openDesign(section, item) {
    const c = (spec) => {
      if (this.openOnClick) {
        this.router.navigate(["/designs/edit"], {
          state: {
            spec: spec,
            resolution: section?.resolution ?? [1080, 1080],
          },
        });
      }
      this.templateSelected.emit(item);
    };

    if (item.spec) {
      c(item.spec);
    } else {
      this.ds.getCreatives(item).subscribe({
        next: (res) => {
          c(res.spec);
        },
      });
    }
  }

  goToBrandGuidelines() {
    this.router.navigate(["brand-guidelines"]);
  }
}
