<confect-card
  [withoutBorders]="withoutBorders"
  [withoutDivide]="withoutDivide"
  [headerInfo]="
    !radioMode
      ? {
          title: 'Designs',
          subtitle: '',
          primaryButton: 'New Design',
          secondaryButton: showImportButton ? 'Import Design' : null,
        }
      : null
  "
  (primary)="router.navigate(['/designs', 'new'])"
  (secondary)="importActivated()"
>
  <confect-card-body>
    @if (isEmpty) {
      <ngx-empty-state [info]="emptyState" (secondaryAction)="learnMore()" />
    }

    @if (!isLoaded) {
      <confect-loading [loading]="!isLoaded" />
    }

    <ngx-folders
      #foldersComponent
      [delegate]="this"
      [settings]="settings"
      [selectMode]="radioMode"
      [mode]="mode"
      [selectedItem]="selectedDesign"
      [extras]="extra"
      [track]="track"
      (itemSelected)="creativeSelected($event)"
    >
      <ng-template folder-item-tmp let-item="item">
        <div class="flex flex-col aspect-square group">
          <div
            class="flex flex-col items-center justify-center h-full w-full p-4 rounded-lg bg-slate-100 shadow-sm text-center cursor-pointer"
          >
            @if (item.design_type === "video") {
              <div
                class="absolute flex items-center justify-center w-full h-full"
              >
                <confect-icon
                  icon="play"
                  iconClass="text-[60px] text-white opacity-75 group-hover:text-primary transition-colors duration-200"
                />
              </div>
            }
            @if (item.thumbnail != null) {
              <div class="w-full h-full">
                <img
                  class="w-full h-full aspect-square rounded object-contain object-center"
                  [src]="item.thumbnail"
                />
              </div>
            }
            @if (item.thumbnail == null) {
              <div class="bg-gray-300 rounded"></div>
            }
          </div>

          <div class="flex items-center justify-start shrink-0">
            @if (item.automation_dependants != null) {
              <div
                class="rounded-full w-3 h-3 flex items-center justify-center ring-inset ring-2 mr-1 shrink-0"
                tooltip
                [tooltipText]="
                  !item.is_live
                    ? 'Used in a catalog, but not live'
                    : 'Used in a live catalog'
                "
                tooltipPosition="left"
                [ngClass]="{
                  'bg-green-400 ring-green-500': item.is_live,
                  'bg-yellow-200 ring-yellow-300': !item.is_live,
                }"
              >
                <div
                  class="rounded-full w-full h-full blur-sm"
                  [ngClass]="{
                    'bg-green-400': item.is_live,
                    'bg-yellow-300': !item.is_live,
                  }"
                ></div>
              </div>
            }
            <h3 class="text-xs font-medium text-gray-700 py-2 pr-4">
              {{ item.name }}
            </h3>
          </div>
        </div>
      </ng-template>
    </ngx-folders>

    @if (!isEmpty && showIntro && !radioMode && !selectionMode) {
      <h2 class="text-center">Start using your designs</h2>
      <ngx-empty-state [info]="info" />
    }
  </confect-card-body>
</confect-card>

<confect-modal #companyCreativesModal>
  <div class="text-center mx-auto my-2 font-bold text-2xl text-slate-700">
    Import Design
  </div>
  <div>
    <ngx-designs-overview-import
      [creatives]="COMPANY_CREATIVES"
      (creativeSelected)="importCreativeSelected($event)"
    />
  </div>
  <div class="flex justify-end">
    <confect-button
      (click)="companyCreativesModal.hide()"
      type="white"
      class="mt-5"
      >Close</confect-button
    >
  </div>
</confect-modal>

<ng-template #catalogOptions let-close="close" let-extra="extra">
  <div class="max-w-2xl">
    <h3 class="text-center w-full mb-10">
      Catalogs currently using this design
    </h3>
    <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
      <table class="w-full max-w-full divide-y divide-gray-200 table-fixed">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/3"
            >
              Catalog
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Variants
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Types
            </th>

            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Live
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- Odd row -->
          @for (
            info of extra.item.automation_dependants;
            track info;
            let i = $index
          ) {
            <tr
              class="bg-white hover:bg-gray-100 cursor-pointer"
              [routerLink]="['/automations', info.id]"
              (click)="close(true)"
              [ngClass]="{
                'bg-white ': i % 2 === 0,
                'bg-gray-50': i % 2 === 1,
              }"
            >
              <td class="px-6 py-4 text-xs">
                <span
                  class="block mb-1 font-medium text-gray-900 truncate"
                  [title]="info.group_name"
                  >{{ info.name }}</span
                >
              </td>
              <td class="px-6 py-4 text-xs">
                <span class="block mb-1 font-medium text-gray-900">
                  @for (variant of info.usage; track variant; let i = $index) {
                    @if (i < info.usage.length - 1) {
                      {{ variant.name }},
                    } @else {
                      {{ variant.name }}
                    }
                  }
                </span>
              </td>
              <td class="px-6 py-4 text-xs">
                <span class="block mb-1 font-medium text-gray-900">
                  @for (variant of info.usage; track variant; let i = $index) {
                    @if (i < info.usage.length - 1) {
                      {{ variant.type | split: "_" }},
                    } @else {
                      {{ variant.type | split: "_" }}
                    }
                  }
                </span>
              </td>
              <td class="px-6 py-4 text-xs">
                <div
                  class="rounded-full w-3 h-3 flex items-center justify-center ring-inset ring-2"
                  [ngClass]="{
                    'bg-green-400 ring-green-500': info.is_live,
                    'bg-gray-200 ring-gray-300': !info.is_live,
                  }"
                >
                  <div
                    class="rounded-full w-full h-full blur-sm"
                    [ngClass]="{
                      'bg-green-400': info.is_live,
                      hidden: !info.is_live,
                    }"
                  ></div>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
