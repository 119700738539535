import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  Creative,
  CreativeLayer,
  DesignTemplatesOutput,
  DesignType,
} from "@core/api-models/designs.models";

import { environment } from "environments/environment";
import { Observable, interval, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";

import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class DesignsService extends ApiService {
  parseColor(c) {
    return c
      .replace("rgba(", "")
      .replace("rgb(", "")
      .replace(")", "")
      .split(",")
      .map((r, i) => parseInt(i < 3 ? r : r * 255, 10));
  }

  outputColor(c) {
    if (c.length === 4) {
      const a = c[3] / 255;
      const c2 = c.slice(0, c.length - 1);
      c2.push(a);
      return "rgba(" + c2.join() + ")";
    }
    return "rgb(" + c.join() + ")";
  }

  getCreatives(id = null, formats = false): Observable<any> {
    const base = `${this.endpoint}/designs`;
    const suffix = id != null ? `/${id}` : "";
    const params = {
      params: new HttpParams({ fromObject: { formats: formats } }),
    };
    return this.http.get(`${base}${suffix}`, params);
  }

  getCreativesFolder(
    folderID?,
    orderBy: string | null = null,
    order: string | null = null,
    type: DesignType | null = null,
  ): Observable<any> {
    const p = {};

    if (folderID != null) {
      p["folder"] = folderID;
    }

    if (orderBy != null) {
      p["order_by"] = orderBy;
    }

    if (order != null) {
      p["order"] = order;
    }

    if (type != null) {
      p["design_type"] = type;
    }

    const params = new HttpParams({
      fromObject: p,
    });

    return this.http.get(`${this.endpoint}/designs`, { params: params });
  }

  getAllDesigns(): Observable<Creative[]> {
    return this.http.get<Creative[]>(`${this.endpoint}/designs/all`);
  }

  getCompanyCreatives(): Observable<any> {
    return this.http.get(`${this.endpoint}/designs/all/account-wise`);
  }

  importCompanyCreative(creativeID: number): Observable<any> {
    return this.http.post(`${this.endpoint}/designs/all/import`, {
      id: creativeID,
    });
  }

  duplicateCreative(id): Observable<any> {
    return this.http.post(`${this.endpoint}/designs/${id}/duplicate`, null);
  }

  copyDesignToAllAccounts(id): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/designs/${id}/move`,
      null,
    );
  }

  saveCreative(creative): Observable<any> {
    this.hj.event(creative.id ? "design_updated" : "design_created");
    const suffix = creative.id != null ? `/${creative.id}` : "";
    return this.http.post(`${this.endpoint}/designs${suffix}`, creative);
  }

  deleteCreative(creative): Observable<any> {
    return this.http.delete(`${this.endpoint}/designs/${creative.id}`);
  }

  enqueueVideoDemo(
    spec,
    size,
    preview = false,
    productFilter = null,
    fps = 30,
  ): Observable<any> {
    return this.http.post(`${this.endpoint}/designs/queue-video-demo`, {
      spec: spec,
      width: size[0],
      height: size[1],
      preview: preview,
      product_filter: productFilter,
      fps: fps,
    });
  }

  // enqueueLayerPreview(spec, productFilter = null): Observable<any> {
  //   return this.http.post(
  //     this.endpoint + '/creatives/queue-layer-preview',
  //     {
  //       'spec': spec,
  //       'product_filter': productFilter,
  //     },
  //   );
  // }

  enqueueMultiImagePreview(
    spec,
    resolution,
    productFilter = null,
    page = 1,
  ): Observable<any> {
    return this.http.post(
      this.endpoint + "/designs/queue-multi-image-preview",
      {
        spec: spec,
        resolution: resolution,
        product_filter: productFilter,
        page: page,
      },
    );
  }

  // Element library save
  saveCreativeElement(
    name: string,
    layer: CreativeLayer,
  ): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(`${this.endpoint}/designs/elements`, {
      name: name,
      spec: layer,
      categories: {},
    });
  }

  getMultiImagePreview(
    spec,
    resolution,
    productFilter = null,
    page = 1,
  ): Observable<any> {
    const job = this.enqueueMultiImagePreview(
      spec,
      resolution,
      productFilter,
      page,
    );
    return this.jobObserver(job);
  }

  jobObserver(promise): Observable<any> {
    return new Observable((observer) => {
      promise.subscribe(
        (job) => {
          this.waitForResult(() => this.getVideoStatus(job.job_id)).subscribe(
            (res) => {
              observer.next(res);
              if (res.type === "result") {
                observer.complete();
              }
            },
            (err) => {
              observer.error(err);
            },
          );
        },
        (err) => {
          observer.error(err);
        },
      ); // Enqueue error
    });
  }

  getDesignTemplates(): Observable<DesignTemplatesOutput> {
    return this.http.get<DesignTemplatesOutput>(
      `${this.endpoint}/designs/templates`,
    );
  }

  getVideoSpecs(): Observable<any> {
    /* Gets the spec of all available recipes */
    if (this.spec != null) {
      return of(JSON.parse(JSON.stringify(this.spec)));
    }

    return this.http
      .get(
        this.endpoint + "/meta/design_specs",
        this.params({ debug: environment.debugSpec }),
      )
      .pipe(tap((res) => (this.spec = JSON.parse(JSON.stringify(res)))));
  }

  waitForResult(
    switchFunc: () => Observable<any>,
    pollInterval: number = 800,
  ): Observable<any> {
    return new Observable((observer) => {
      // Keep checking status
      const sub = interval(pollInterval)
        .pipe(switchMap(switchFunc))
        .subscribe(
          (res) => {
            if (res.status === "finished") {
              observer.next({
                value: res.result,
                type: "result",
              });
              sub.unsubscribe();
              observer.complete();
            } else if (res.status === "failed") {
              observer.error(res);
              sub.unsubscribe();
              observer.complete();
            } else {
              // Just next progress
              observer.next({
                value: res.progress,
                type: "progress",
              });
            }
          },
          (err) => {
            observer.error(err);
          },
        );
    });
  }

  getVideoStatus(queueID): Observable<any> {
    return this.http.get(this.baseURL + "/queues/video-render/" + queueID);
  }
}
