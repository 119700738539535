import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from "@angular/core";

import { FolderService } from "@core/api/folder.service";
import { SharedElementsService } from "@core/api/shared-elements.service";
import { SharedElementInterface } from "@core/models/shared-elements.types";

import {
  FolderContents,
  FolderInfo,
  FoldersComponent,
  ItemDelegate,
  TypeSettings,
} from "@components/folders";

import { EmptyStateInfo } from "@theme/components/empty-state/empty-state.component";

import { Observable, map } from "rxjs";

@Component({
  selector: "ngx-design-elements-overview",
  templateUrl: "./design-elements-overview.component.html",
  standalone: false,
})
export class DesignElementsOverviewComponent
  implements ItemDelegate<SharedElementInterface>
{
  private elementService = inject(SharedElementsService);
  protected folderService = inject(FolderService);

  _radioMode: boolean = false;
  empty_size: "default" | "small" = "default";

  @Input() set radioMode(to: boolean) {
    if (to == null) {
      return;
    }
    this._radioMode = to;
    if (to) {
      this.empty_size = "small";
    }
  }
  @Input() mode: "radio" | "submit" = "radio";
  @Input() selectedDesignElement = null;
  @Input() set header(to: boolean) {
    this.settings = {
      grid: true,
      header: to ?? true,
      type: "creative_element",
      display_type: { item: "Design Element", particle: "a" },
    };
  }

  empty_state: EmptyStateInfo = {
    img: "assets/images/empty-states/elements.png",
    title: "You don't currently have any design elements.",
    description:
      "Instead of recreating the same splash every time, save it as a design element and reuse it for every new design.",
  };

  settings: TypeSettings = {
    grid: true,
    header: true,
    type: "creative_element",
    display_type: { item: "Design Element", particle: "a" },
  };

  @Output() selected = new EventEmitter<SharedElementInterface>();

  @ViewChild("folders") folders: FoldersComponent<SharedElementInterface>;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  loadFolder(
    folder: FolderInfo,
    options?,
  ): Observable<FolderContents<SharedElementInterface>> {
    return this.elementService.getUserElementsFolder(folder?.id).pipe(
      map((res) => {
        return {
          name: folder?.name,
          id: folder?.id,
          items: res.contents,
          folders: res.folders,
        };
      }),
    );
  }

  openItem(item: any) {}

  duplicateItem(item: SharedElementInterface): Observable<number> {
    return this.elementService
      .createElement({
        name: item.name + " - duplicate",
        categories: item.categories,
        spec: item.spec,
      })
      .pipe(
        map((res) => {
          return res.id;
        }),
      );
  }

  renameItem(item: SharedElementInterface): Observable<boolean> {
    return this.elementService.updateElement(item, item.name).pipe(
      map((res) => {
        return res.success;
      }),
    );
  }

  deleteItem(item: SharedElementInterface): Observable<boolean> {
    return this.elementService.deleteElement(item.id).pipe(
      map((res) => {
        return res.success;
      }),
    );
  }

  getItemImage(item: SharedElementInterface) {
    return item.thumbnail;
  }
  actionsActivated(action: string, item: SharedElementInterface) {}
  elementSelected(element) {
    this.selected.emit(element);
  }
}
