import { ApplicationRef, Injectable, inject } from "@angular/core";

import { AccountState } from "@core/api-models/settings.models";
import { SettingsService } from "@core/api/settings.service";

import { Observable, map } from "rxjs";

import { AccessService } from "./access.service";
import { DataBaseService } from "./base/data-base-service";
import { ProctorService } from "./proctor.service";

@Injectable({
  providedIn: "root",
})
export class AccountStateService extends DataBaseService<AccountState> {
  appRef: ApplicationRef;
  accessService: AccessService;
  proctorService: ProctorService;
  settingsService = inject(SettingsService);

  refresher(): Observable<AccountState> {
    return this.settingsService.getAccount().pipe(map((res) => res.state));
  }

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    const appRef = inject(ApplicationRef);
    const accessService = inject(AccessService);
    const proctorService = inject(ProctorService);

    super(appRef, accessService, proctorService, false);
    this.appRef = appRef;
    this.accessService = accessService;
    this.proctorService = proctorService;

    this.init();
  }

  init(): void {
    this.getData(true);
  }
}
